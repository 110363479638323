import request from '@/utils/request'
import { SetStoryOnlineParams } from '@/types/tenantStory'
/**
 * 企业下story列表
 */
export const getTenantStory = (url: string) => {
  return request({
    url,
    method: 'get'
  })
}

/**
 * 企业下story上下架
 */
export const setStoryOnline = (params: SetStoryOnlineParams) => {
  return request({
    url: `${params.url}?online=${params.online}&storyLineId=${params.storyLineId}`,
    method: 'post'
  })
}

/**
 * story 详情
 */
export const storyDetail = (params: {storyLineId: string }) => {
  return request({
    url: `/blackcat/story/line/detail?storyLineId=${params.storyLineId}`,
    method: 'get',
  })
}
