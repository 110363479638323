






















import { Component, Vue, Prop } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import storyItem from './storyItem.vue'
import { getTenantStory } from '@/api/tenantStory';
import { StoryList } from '@/types/tenantStory'

const tabs = [
  {
    label: '投资规划',
    name: '1'
  },
  {
    label: '家庭基础保障',
    name: '2'
  },
  {
    label: '教育规划',
    name: '3'
  },
  {
    label: '退休规划',
    name: '4'
  },
  {
    label: '其他规划',
    name: '5'
  }
]

@Component({
  name: 'storyListCom',
  components: {
    moduleHeader,
    storyItem
  }
})
export default class extends Vue {
  @Prop() dataObj!: { getListUrl: string; directUrl: string; type: string };
  @Prop({ default: () => tabs }) tabs!: Array<object>;

  activeName = '0';

  storyItem = storyItem;
  dataSource: StoryList[] = new Array(5).fill({
    storyLineType: null,
    storyLineTypeName: '',
    storyLines: []
  });

  async created () {
    const { activeName } = this.$route.query
    if (activeName) {
      this.activeName = activeName as string
    }
    // 按照固定顺序排序
    // const defineOrder = [
    //   '理念相关',
    //   '产品相关',
    //   '行业相关',
    //   '投资规划',
    //   '家庭基础保障',
    //   '子女教育规划',
    //   '退休养老规划',
    //   '其他'
    // ]

    const defineOrder = [
      'OthersInvest',
      'OthersRisk',
      'OthersAllocation',
      'Invest',
      'Family',
      'Child',
      'Pension',
      'Others'
    ]

    const res = await getTenantStory(this.dataObj.getListUrl)
    const resData = res.data
    const newArr: StoryList[] = []
    defineOrder.forEach(e1 => {
      resData.forEach((element: StoryList) => {
        if (e1 === element.storyLineType) {
          newArr.push(element)
        }
      })
    })
    if (res.statusCode === 0 && res.data) {
      if (this.dataObj.type === 'otherOption') {
        this.dataSource = newArr.filter((itemStoryLine: StoryList) => {
          itemStoryLine.storyLines = itemStoryLine.storyLines.filter(
            item => item.userOnline
          )
          console.log('arr', itemStoryLine)
          return (
            itemStoryLine.storyLines && itemStoryLine.storyLines.length > 0
          )
        })
      } else {
        this.dataSource = newArr.filter((itemStoryLine: StoryList) => {
          return (
            itemStoryLine.storyLines && itemStoryLine.storyLines.length > 0
          )
        })
      }
    }
  }

  handleClick () {
    console.log(1)
  }
}
