





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { StoryList, StoryLinesItem } from '@/types/tenantStory'

@Component({
  name: 'storyItem',
})
export default class extends Vue {
  @Prop() data!: StoryList;
  @Prop() activeName!: string;
  @Prop() dataObj!: {
    getListUrl: string;
    directUrl: string;
    onLineType: string
  };

  mounted () {
    console.log('this.data', this.$route)
  }

  toDetail (card: StoryLinesItem) {
    console.log('card', card)
    this.$router.push({
      name: this.dataObj.directUrl,
      query: {
        id: card.id,
        activeName: this.activeName,
        url: this.$route.name
      },
    })
    console.log('card', card)
  }
}
