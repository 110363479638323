




import { Component, Vue } from 'vue-property-decorator'
import detailCom from '@/views/manage/tenantStory/template/storyDetail/detailCom.vue'
@Component({
  name: 'detail',
  components: {
    detailCom
  }
})
export default class extends Vue {
  sourceData = {
    upDownUrl: '/blackcat/story/line/user/online',
    onLineType: 'userOnline',
    type: 'otherOption'
  }
}
