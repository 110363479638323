






import { Component, Vue } from 'vue-property-decorator'
import storyListCom from '@/views/manage/tenantStory/template/storyList/storyListCom.vue'

@Component({
  name: '',
  components: {
    storyListCom
  }
})
export default class extends Vue {
   dataObj = {
     getListUrl: '/blackcat/story/line/user/list/others',
     directUrl: 'otherOptDetail',
     onLineType: 'userOnline',
     listTitle: '财富聚焦',
     type: 'otherOption'
   }

   tabs = [
     {
       label: '投资相关',
       name: '1'
     },
     {
       label: '潜在风险相关',
       name: '2'
     },
     {
       label: '分配相关',
       name: '3'
     }
   ]
}
