























import { Component, Vue, Prop } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { setStoryOnline, storyDetail } from '@/api/tenantStory'
import { StoryLinesItem } from '@/types/tenantStory'

@Component({
  name: 'detailCom',
  components: {
    moduleHeader
  }
})
export default class extends Vue {
  @Prop() dataObj!: {upDownUrl: string; onLineType: string; type?: string }
  storyData: StoryLinesItem = {
    id: '',
    tenantId: null,
    createTime: '',
    createBy: '',
    createByName: null,
    deleted: false,
    storyLineName: '',
    storyLineType: '',
    subStoryLineType: null,
    comment: '',
    marketName: '',
    marketDescription: '',
    displayName: '',
    iasGroupId: '',
    storyLineUrl: '',
    defaultSelected: true,
    online: true,
    updateBy: '',
    coverUrl: '',
    updateByName: '',
    tenantOnline: false,
    userOnline: false
  }

  storyId = ''
  value = false

  get getTitle () {
    return this.dataObj.type === 'otherOption' ? this.storyData.storyLineName : this.storyData.marketName
  }

  created () {
    this.storyId = this.$route.query.id as string
    this.onStoryDetail()
    console.log('this.',)
  }

  async onStoryDetail () {
    const res = await storyDetail({
      storyLineId: this.storyId
    })
    if (res.statusCode === 0 && res.data) {
      this.storyData = res.data
      console.log('res storyData', res)
    }
  }

  async handleChange (event: boolean) {
    console.log('event', event)
    const res = await setStoryOnline({
      url: this.dataObj.upDownUrl,
      online: event,
      storyLineId: this.storyData.id
    })
    if (res.statusCode === 0 && res.data) {
      this.$message.success('操作成功')
    }
  }

  backup () {
    const { activeName, url } = this.$route.query
    this.$router.push({
      name: url as string,
      query: {
        activeName: activeName as string
      }
    })
  }
}
